var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-length"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"message_length","extra-menu-items":[_vm.ExtraSettingsMenuItems.AdvancedSettings, _vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings mt-5"},[_c('a-card',{attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"message_length","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.AdvancedSettings)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"advanced_settings"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_min_lines',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_max_lines',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_min',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_max',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_words_count_min',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'message_length_words_count_max',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}})]},proxy:true}],null,false,3544525110)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"message_length","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,3149551453)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"message_length","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,3334065424)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }